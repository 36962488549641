import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// project imports
import Logo from '../../../assets/images/logo.png';
import LogoDark from '../../../assets/images/logoDark.png';


// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;

    return (
        <Link component={RouterLink} to='numbers' aria-label="logo">
            {mode === 'dark' ?
                <img src={LogoDark} alt="logo" style={{ height: '40px', width: '40px' }} />
                :
                <img src={Logo} alt="logo" style={{ height: '40px', width: '40px' }} />
            }
        </Link>
    )
}

export default LogoSection;
