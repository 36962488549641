/* eslint-disable */
import { lazy } from 'react';

// project imports
import Loadable from '../ui-elements/Loadable';
import MainLayout from '../layout/MainLayout';

// GUARDS IMPORT
import AuthGuard from '../utils/guards/AuthGuard';
import { NumberBuyGuard, NumbersReadGuard, NumbersSettingGuard } from '../utils/guards/NumbersGuard';
import { ViewAgentsGuard } from '../utils/guards/AssistantGuards';
import { CallHistoryReadGuard } from '../utils/guards/CallHistoryGuard';
import { ApiKeysReadGuard } from '../utils/guards/ApiKeyGuard';
import NavMotion from '../layout/NavMotion';
import { ObcReadGuard, ObcWriteGuard } from '../utils/guards/OutboundGuard';

const Agents = Loadable(lazy(() => import('../views/agents')));
const FinancialAgents = Loadable(lazy(() => import('../views/financialAgent')));
const AgentAnalytics = Loadable(lazy(() => import('../views/aiManager')));
const NumberList = Loadable(lazy(() => import('../views/numbers')));
const BuyNumber = Loadable(lazy(() => import('../views/numbers/buyNumber')));

const UserProfile = Loadable(lazy(() => import('../views/user-profile')));
const PhoneNumberSettings = Loadable(lazy(() => import('../views/numbers/phoneNumberSettings')));
const CallHistory = Loadable(lazy(() => import('../views/callHistory')));
const SupportFrom = Loadable(lazy(() => import('../views/support')));
const SupportReplies = Loadable(lazy(() => import('../views/support/supportReplies')));
const PostTransactionPage = Loadable(lazy(() => import('../views/pages/others/postTransactionPage')));
const SupportTable = Loadable(lazy(() => import('../views/support/supportTable')));
const APIKeyList = Loadable(lazy(() => import('../views/apiKey')));
const PersonalAssistant = Loadable(lazy(() => import('../views/personalAssistant')));
const ChatBots = Loadable(lazy(() => import('../views/chatBot')));

const OutboundCallTable = Loadable(lazy(() => import('../views/outboundCall/obcTable')));
const OutboundCallForm = Loadable(lazy(() => import('../views/outboundCall/createEditObc')));
const Organization = Loadable(lazy(() => import('../views/organization')));
const ObcLogsPage = Loadable(lazy(() => import('../views/outboundCall/obcLogs')));


//No Lazy Import
import QuickStart from '../views/dashboard/quickStart'
import AgentTemplateSelect from '../views/dashboard/agentTemplates'
import HDFCDemoObcCall from '../views/outboundCall/HDFCDemoObc';

const AdminRoutes = {
    path: '/dashboard/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: "numbers",
            element: (
                <NumbersReadGuard>
                    <NavMotion>
                        <NumberList />
                    </NavMotion>
                </NumbersReadGuard>
            )
        },
        {
            path: "numbers/setting/:id",
            element: (
                <NumbersReadGuard>
                    <NavMotion>
                        <PhoneNumberSettings />
                    </NavMotion>
                </NumbersReadGuard>

            )
        },
        {
            path: "numbers/buy",
            element: (
                <NumberBuyGuard>
                    <NavMotion>
                        <BuyNumber />
                    </NavMotion>
                </NumberBuyGuard>

            )
        },
        {
            path: "assistants",
            children: [
                {
                    path: "other",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <Agents />
                            </NavMotion>
                        </ViewAgentsGuard>
                    )
                },
                {
                    path: "other/:id",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <Agents />
                            </NavMotion>
                        </ViewAgentsGuard>

                    )
                },
                {
                    path: "financial",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <FinancialAgents />
                            </NavMotion>
                        </ViewAgentsGuard>
                    )
                },
                {
                    path: "financial/:id",
                    element: (
                        <ViewAgentsGuard>
                            <NavMotion>
                                <FinancialAgents />
                            </NavMotion>
                        </ViewAgentsGuard>
                    )
                },
            ]
        },
        // {
        //     path: "agents",
        //     element: (
        //         <ViewAgentsGuard>
        //             <NavMotion>
        //                 <Agents />
        //             </NavMotion>

        //         </ViewAgentsGuard>
        //     )
        // },
        // {
        //     path: "agents/:id",
        //     element: (
        //         <ViewAgentsGuard>
        //             <NavMotion>
        //                 <Agents />
        //             </NavMotion>
        //         </ViewAgentsGuard>

        //     )
        // },
        // {
        //     path: "agents/financial",
        //     element: (
        //         <ViewAgentsGuard>
        //             <NavMotion>
        //                 <FinancialAgents />
        //             </NavMotion>
        //         </ViewAgentsGuard>
        //     )
        // },
        // {
        //     path: "agents/financial/:id",
        //     element: (
        //         <ViewAgentsGuard>
        //             <NavMotion>
        //                 <FinancialAgents />
        //             </NavMotion>
        //         </ViewAgentsGuard>
        //     )
        // },
        {
            path: "personal-assistant",
            element: (

                <NavMotion>
                    <PersonalAssistant />
                </NavMotion>

            )
        },
        {
            path: "personal-assistant/:id",
            element: (
                <NavMotion>
                    <PersonalAssistant />
                </NavMotion>

            )
        },
        {
            path: "chatBots",
            element: (
                <NavMotion>
                    <ChatBots />
                </NavMotion>

            )
        },
        {
            path: "chatBots/:id",
            element: (
                <NavMotion>
                    <ChatBots />
                </NavMotion>

            )
        },
        {
            path: "ai-manager",
            element: (
                <>
                    <AgentAnalytics />
                </>

            )
        },
        {
            path: "call-history",
            element: (
                <CallHistoryReadGuard>
                    <NavMotion>
                        <CallHistory />
                    </NavMotion>
                </CallHistoryReadGuard>

            )
        },
        {
            path: "call-history/:id",
            element: (
                <CallHistoryReadGuard>
                    <NavMotion>
                        <CallHistory />
                    </NavMotion>
                </CallHistoryReadGuard>

            )
        },
        // {
        //     path: "subscription-plan",
        //     element: (
        //         <SubscriptionReadGuard>
        //             <NavMotion>
        //                 <SubscriptionPlan />
        //             </NavMotion>
        //         </SubscriptionReadGuard>

        //     )
        // },
        {
            path: "support",
            element: (
                <NavMotion>
                    <SupportTable />
                </NavMotion>

            )
        },
        {
            path: "support/create",
            element: (
                <NavMotion>
                    <SupportFrom />
                </NavMotion>

            )
        },
        {
            path: "support/replies",
            element: (
                <NavMotion>
                    <SupportReplies />
                </NavMotion>

            )
        },
        {
            path: "developer/api-keys",
            element: (
                <ApiKeysReadGuard>
                    <NavMotion>
                        <APIKeyList />
                    </NavMotion>
                </ApiKeysReadGuard>

            )
        },
        {
            path: "outbound-call",
            element: (
                <NavMotion>
                    <ObcReadGuard>
                        <OutboundCallTable />
                    </ObcReadGuard>
                </NavMotion>

            )
        },
        {
            path: "outbound-call/create",
            element: (
                <NavMotion>
                    <ObcWriteGuard>
                        <OutboundCallForm />
                    </ObcWriteGuard>
                </NavMotion>

            )
        },
        {
            path: "outbound-call/edit/:id",
            element: (
                <NavMotion>
                    <ObcWriteGuard>
                        <OutboundCallForm />
                    </ObcWriteGuard>
                </NavMotion>

            )
        },
        {
            path: "outbound-call/logs",
            element: (
                <NavMotion>
                    <ObcReadGuard>
                        <ObcLogsPage />
                    </ObcReadGuard>
                </NavMotion>

            )
        },
        {
            path: "demo-outbound-call",
            element: (
                <NavMotion>
                    <ObcReadGuard>
                        <HDFCDemoObcCall />
                    </ObcReadGuard>
                </NavMotion>

            )
        },
        {
            path: "user-profile",
            element: (
                <NavMotion>
                    <UserProfile />
                </NavMotion>

            )
        },
        {
            path: "organization",
            element: (
                <NavMotion>
                    <Organization />
                </NavMotion>

            )
        },
        {
            path: "post-transaction",
            element: (
                <NavMotion>
                    <PostTransactionPage />
                </NavMotion>

            )
        },
        {
            path: "quick-start",
            element: (
                <NavMotion>
                    <QuickStart />
                </NavMotion>
            )
        },
        {
            path: "quick-start/select-template",
            element: (
                <NavMotion>
                    <AgentTemplateSelect />
                </NavMotion>
            )
        },

    ]
};

export default AdminRoutes