
import PropTypes from 'prop-types';
import React from 'react';
import { Collapse, Fade, Box, Grow, Slide, Zoom } from '@mui/material';

// ==============================|| TRANSITIONS ||============================== //

const Transitions = React.forwardRef(({ children, position, type, direction, in: show, timeout, buffer = 50, ...others }, ref) => {
    let positionSX = {
        transformOrigin: '0 0 0',
        ...(type === 'slide' && {
            ...(direction === 'left' && { transform: `translateX(${buffer}px)` }),
            ...(direction === 'right' && { transform: `translateX(-${buffer}px)` }),
            ...(direction === 'up' && { transform: `translateY(${buffer}px)` }),
            ...(direction === 'down' && { transform: `translateY(-${buffer}px)` }),
        }),
    };

    switch (position) {
        case 'top-right':
            positionSX = {
                transformOrigin: 'top right'
            };
            break;
        case 'top':
            positionSX = {
                transformOrigin: 'top'
            };
            break;
        case 'bottom-left':
            positionSX = {
                transformOrigin: 'bottom left'
            };
            break;
        case 'bottom-right':
            positionSX = {
                transformOrigin: 'bottom right'
            };
            break;
        case 'bottom':
            positionSX = {
                transformOrigin: 'bottom'
            };
            break;
        case 'top-left':
            positionSX = {
                transformOrigin: '0 0 0'
            };
            break;
        case 'top-bottom':
            positionSX = {
                transformOrigin: 'top center'
            };
            break;
        case 'bottom-top':
            positionSX = {
                transformOrigin: 'bottom center'
            };
            break;
        default:
            positionSX = {
                transformOrigin: '0 0 0'
            };
            break;
    }

    return (
        <Box ref={ref}>
            {type === 'grow' && (
                <Grow in={show} timeout={timeout} {...others}>
                    <Box sx={positionSX}>{children}</Box>
                </Grow>
            )}
            {type === 'collapse' && (
                <Collapse in={show} timeout={timeout} sx={positionSX}>
                    {children}
                </Collapse>
            )}
            {type === 'fade' && (
                <Fade in={show} timeout={timeout} {...others}>
                    <Box sx={positionSX}>{children}</Box>
                </Fade>
            )}
            {type === 'slide' && (
                <Slide
                    in={show}
                    timeout={timeout}
                    direction={direction || 'up'}
                    {...others}
                    sx={{
                        ...(direction === 'left' && { transform: `translateX(${buffer}px)` }),
                        ...(direction === 'right' && { transform: `translateX(-${buffer}px)` }),
                        ...(direction === 'up' && { transform: `translateY(${buffer}px)` }),
                        ...(direction === 'down' && { transform: `translateY(-${buffer}px)` }),
                    }}
                >
                    <Box sx={positionSX}>{children}</Box>
                </Slide>
            )}
            {type === 'zoom' && (
                <Zoom in={show} timeout={timeout} {...others}>
                    <Box sx={positionSX}>{children}</Box>
                </Zoom>
            )}
        </Box>
    );
});

Transitions.propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(['grow', 'fade', 'collapse', 'slide', 'zoom']),
    position: PropTypes.oneOf(['top-left', 'top-right', 'top', 'bottom-left', 'bottom-right', 'bottom', 'top-bottom', 'bottom-top']),
    direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
    in: PropTypes.bool,
    timeout: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            appear: PropTypes.number,
            enter: PropTypes.number,
            exit: PropTypes.number
        })
    ]),
    buffer: PropTypes.number
};

Transitions.defaultProps = {
    type: 'grow',
    position: 'top-left',
    direction: 'up',
    in: true,
    timeout: {
        appear: 0,
        enter: 400,
        exit: 200
    },
    buffer: 50 // Default buffer value (in pixels)
};

export default Transitions;

