/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError } from '../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    callHistory: null,
    singleCallData: null,
    obcCallHistory: null,
    singleObcCallData: null,

    totalPages: null,
    totalCount: null,
    resSummary: null,
    loading: false,
    singleCallDataLoading: false,

    // Filters state
    filters: {
        search: { value: "", disabled: false, type: "string" }, // Search filter
        call_type: { value: "inbound", disabled: false, type: "string" }, // Call Type filter for ex: inbound, outbound
        start_date: { value: "", disabled: false, type: "date" }, // Start Date filter
        end_date: { value: "", disabled: false, type: "date" }, // End Date filter
        call_order: { value: "asc", disabled: false, type: "string" }, // Call Order filter for ex: asc, desc
    },
    showCallDetails: false,
    currentPage: 1,
    totalResPerPage: 10
};

const slice = createSlice({
    name: 'callHistory',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },
        isSingleCallDataLoading(state, action) {
            state.singleCallDataLoading = action.payload;
        },

        setCallHistoryDataSuccess(state, action) {
            state.callHistory = action?.payload?.data;
            state.totalPages = action?.payload?.totalPages;
            state.totalCount = action?.payload?.totalCount;
            state.resSummary = action?.payload?.resSummary;
        },
        setObcCallHistoryDataSuccess(state, action) {
            state.obcCallHistory = action?.payload?.data;
            state.totalPages = action?.payload?.totalPages;
            state.totalCount = action?.payload?.totalCount;
            state.resSummary = action?.payload?.resSummary;
        },
        setSingleCallDataSuccess(state, action) {
            state.singleCallData = action.payload;
        },
        setSingleObcCallDataSuccess(state, action) {
            state.singleObcCallData = action.payload;
        },

        // Filters Management with Disabled State
        setFilter(state, action) {
            const { name, value } = action.payload;
            if (state.filters[name]) {
                state.filters[name].value = value;
            }
        },
        setFilterDisabled(state, action) {
            const { name, disabled } = action.payload;
            if (state.filters[name]) {
                state.filters[name].disabled = disabled;
            }
        },
        clearFilter(state, action) {
            const { name } = action.payload;
            if (state.filters[name]) {
                state.filters[name].value = initialState.filters[name].value;
            }
        },
        clearAllFilters(state) {
            state.filters = { ...initialState.filters };
        },
        handleShowCallDetails(state, action) {
            state.showCallDetails = action.payload;
        },
        //Page change management
        changeCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Filter Actions
export const { setFilter, clearFilter, clearAllFilters, changeCurrentPage, setFilterDisabled, handleShowCallDetails } = slice.actions;

// ----------------------------------------------------------------------

// Selectors for Filters
export const selectFilters = (state) => state.callHistory.filters;

// API Calls
export function getAllCallHistoryData({ page, limit }) {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.isLoading(true));

            // Extract filters from state
            const { filters } = getState().callHistory;
            const { search, start_date, end_date, call_type, call_order } = filters;

            // Simulate loading effect
            const timer = page === 1 ? 500 : 1500;
            await new Promise((resolve) => setTimeout(resolve, timer));

            const response = await axios.get(
                `/call-history/get-all?search=${search?.value}&start_date=${start_date?.value}&end_date=${end_date?.value}&call_type=${call_type?.value}&call_order=${call_order?.value}&page=${page}&limit=${limit}`
            );
            dispatch(slice.actions.setCallHistoryDataSuccess(response.data));

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getCallHistoryData({ page, limit }) {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.isLoading(true));

            // Extract filters from state
            const { filters } = getState().callHistory;
            const { search, start_date, end_date, call_type, call_order } = filters;

            // Simulate loading effect
            const timer = page === 1 ? 500 : 1500;
            await new Promise((resolve) => setTimeout(resolve, timer));

            const response = await axios.get(
                `/call-history/get?search=${search?.value}&start_date=${start_date?.value}&end_date=${end_date?.value}&call_type=${call_type?.value}&call_order=${call_order?.value}&page=${page}&limit=${limit}`
            );
            dispatch(slice.actions.setCallHistoryDataSuccess(response.data));

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getSingleCallData(callHistoryId) {
    return async () => {
        try {
            dispatch(slice.actions.isSingleCallDataLoading(true));

            const response = await axios.get(`/call-history/get/${callHistoryId}`);
            dispatch(slice.actions.setSingleCallDataSuccess(response.data?.data));

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            setTimeout(async () => {
                dispatch(slice.actions.isSingleCallDataLoading(false));
            }, 800);
        }
    };
}

export function getObcCallHistoryData({ page, limit }) {
    return async (_, getState) => {
        try {
            dispatch(slice.actions.isLoading(true));

            // Extract filters from state
            const { filters } = getState().callHistory;
            const { search, start_date, end_date, call_type, call_order } = filters;

            // Simulate loading effect
            const timer = page === 1 ? 500 : 1500;
            await new Promise((resolve) => setTimeout(resolve, timer));

            const response = await axios.get(
                `/call-history/get-outbound-history?search=${search?.value}&start_date=${start_date?.value}&end_date=${end_date?.value}&call_type=${call_type?.value}&call_order=${call_order?.value}&page=${page}&limit=${limit}`
            );
            dispatch(slice.actions.setObcCallHistoryDataSuccess(response.data));

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getSingleObcCallData(callHistoryId) {
    return async () => {
        try {
            dispatch(slice.actions.isSingleCallDataLoading(true));

            const response = await axios.get(`/call-history/get-outbound-history/${callHistoryId}`);
            dispatch(slice.actions.setSingleObcCallDataSuccess(response.data?.data));

            return response.data?.data;
        }
        catch (err) {
            throw err;
        }
        finally {
            setTimeout(async () => {
                dispatch(slice.actions.isSingleCallDataLoading(false));
            }, 800);
        }
    };
}
