// // material-ui
// import { createTheme } from '@mui/material/styles';

// // assets
// import defaultColor from '../assets/scss/_themes-vars.module.scss';
// import theme1 from '../assets/scss/_theme1.module.scss';
// import theme2 from '../assets/scss/_theme2.module.scss';
// import theme3 from '../assets/scss/_theme3.module.scss';
// import theme4 from '../assets/scss/_theme4.module.scss';
// import theme5 from '../assets/scss/_theme5.module.scss';
// import theme6 from '../assets/scss/_theme6.module.scss';

// // ==============================|| DEFAULT THEME - PALETTE  ||============================== //

// const Palette = (navType, presetColor) => {
//     let colors;
//     switch (presetColor) {
//         case 'theme1':
//             colors = theme1;
//             break;
//         case 'theme2':
//             colors = theme2;
//             break;
//         case 'theme3':
//             colors = theme3;
//             break;
//         case 'theme4':
//             colors = theme4;
//             break;
//         case 'theme5':
//             colors = theme5;
//             break;
//         case 'theme6':
//             colors = theme6;
//             break;
//         case 'default':
//         default:
//             colors = defaultColor;
//     }

//     return createTheme({
//         palette: {
//             mode: navType,
//             common: {
//                 black: colors.darkPaper
//             },
//             primary: {
//                 light: navType === 'dark' ? colors.darkPrimaryLight : colors.primaryLight,
//                 main: navType === 'dark' ? colors.darkPrimaryMain : colors.primaryMain,
//                 dark: navType === 'dark' ? colors.darkPrimaryDark : colors.primaryDark,
//                 200: navType === 'dark' ? colors.darkPrimary200 : colors.primary200,
//                 800: navType === 'dark' ? colors.darkPrimary800 : colors.primary800
//             },
//             secondary: {
//                 light: navType === 'dark' ? colors.darkSecondaryLight : colors.secondaryLight,
//                 main: navType === 'dark' ? colors.darkSecondaryMain : colors.secondaryMain,
//                 dark: navType === 'dark' ? colors.darkSecondaryDark : colors.secondaryDark,
//                 200: navType === 'dark' ? colors.darkSecondary200 : colors.secondary200,
//                 800: navType === 'dark' ? colors.darkSecondary800 : colors.secondary800,
//                 tertiary: '#0C90D0'
//             },
//             error: {
//                 light: colors.errorLight,
//                 main: colors.errorMain,
//                 dark: colors.errorDark
//             },
//             orange: {
//                 light: colors.orangeLight,
//                 main: colors.orangeMain,
//                 dark: colors.orangeDark
//             },
//             warning: {
//                 light: colors.warningLight,
//                 main: colors.warningMain,
//                 dark: colors.warningDark
//             },
//             success: {
//                 light: colors.successLight,
//                 200: colors.success200,
//                 main: colors.successMain,
//                 dark: colors.successDark
//             },
//             grey: {
//                 50: colors.grey50,
//                 100: colors.grey100,
//                 300: '#F5F6FA',
//                 500: navType === 'dark' ? colors.darkTextSecondary : colors.grey500,
//                 600: navType === 'dark' ? colors.darkTextTitle : colors.grey900,
//                 700: navType === 'dark' ? colors.darkTextPrimary : colors.grey700,
//                 900: navType === 'dark' ? colors.darkTextPrimary : colors.grey900
//             },
//             dark: {
//                 light: colors.darkTextPrimary,
//                 main: colors.darkLevel1,
//                 dark: colors.darkLevel2,
//                 800: colors.darkBackground,
//                 900: colors.darkPaper
//             },
//             text: {
//                 primary: navType === 'dark' ? colors.darkTextPrimary : colors.grey700,
//                 secondary: navType === 'dark' ? colors.darkTextSecondary : colors.grey500,
//                 dark: navType === 'dark' ? colors.darkTextPrimary : colors.grey900,
//                 hint: colors.grey100,
//                 tertiary: '#0C90D0',
//                 menuText: '#64748B',
//             },
//             divider: navType === 'dark' ? colors.darkTextPrimary : colors.grey200,
//             background: {
//                 paper: navType === 'dark' ? colors.darkLevel2 : colors.paper,
//                 default: navType === 'dark' ? colors.darkPaper : colors.paper,
//                 secondary: navType === 'dark' ? colors.darkBackground : '#D9F5FF',
//                 main: '#3EC5F4'
//             }
//         }
//     });
// };

// export default Palette;
// material-ui

import { createTheme } from '@mui/material/styles';

// assets
import defaultColor from '../assets/scss/_themes-vars.module.scss';
import theme from '../assets/scss/theme.module.scss';


// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (navType) => {
    let colors = theme;

    return createTheme({
        palette: {
            mode: navType,
            primary: {
                main: navType === 'dark' ? colors.primaryDark : colors.primaryLight, // Ensure this exists in your theme file
            },
            secondary: {
                main: navType === 'dark' ? colors.secondaryDark : colors.secondaryLight, // Ensure this exists in your theme file
            },
            tertiary: {
                main: navType === 'dark' ? colors.tertiaryDark : colors.tertiaryLight, // Ensure this exists in your theme file
            },
            success: {
                main: navType === 'dark' ? colors.successDark : colors.successLight,
                secondary: colors.successSecondary,
            },
            warning: {
                main: navType === 'dark' ? colors.warningDark : colors.warningLight,
                light: colors.warningLight,
                dark: colors.warningDark,
                orange: colors.warningOrange
            },
            error: {
                main: navType === 'dark' ? colors.errorDark : colors.errorLight,
            },
            text: {
                main: navType === 'dark' ? colors.primaryDark : colors.primaryLight,
                primary: navType === 'dark' ? colors.primaryTextDark : colors.primaryTextLight,
                secondary: navType === 'dark' ? colors.secondaryTextDark : colors.secondaryTextLight,
                light: colors.lightText,
                dark: colors.darkText
            },
            border: {
                main: navType === 'dark' ? colors.primaryBorderDark : colors.primaryBorderLight,
            },
            background: {
                default: navType === 'dark' ? colors.mainDark : colors.mainLight,
                paper: navType === 'dark' ? colors.contentDark : colors.contentLight,
                main: colors.primaryLight,
                secondary: colors.secondaryLight,
                light: colors.mainLight,
                dark: colors.mainDark,
                gradientLight: colors.primaryGradientLight,
                gradientDark: colors.primaryGradientDark,
                gray: colors.primaryBorderLight,
                gradientSecondaryDark: colors.secondaryGradientDark,
                modalDark: colors.modalDark
            },
        },
    });
};

export default Palette;
