// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';

// project imports
import useConfig from '../../../hooks/useConfig';
import useAuth from '../../../hooks/useAuth';

import { useDispatch, useSelector } from '../../../store';
import { openDrawer } from '../../../store/slices/menu';


import { Icon } from '@iconify/react/dist/iconify.js';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { matchDownMd } = useConfig()

    return (
        <>
            <Box
                sx={{
                    width: drawerOpen ? 250 : 67,
                    display: 'flex',
                    alignItems: 'end',
                    transition: 'all .2s ease-in-out',
                    justifyContent: 'end',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        border: !matchDownMd && '2px solid #E9E9E9',
                        borderRadius: !matchDownMd && '20px',
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        '&:hover': {
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                        },
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    {drawerOpen ?
                        <Icon icon="gg:menu-left-alt" className='w-6 h-6' /> :
                        <Icon icon="gg:menu-left-alt" className='w-6 h-6' />
                    }
                </Avatar>

            </Box >

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

        </>
    );
};

export default Header;
