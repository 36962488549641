/* eslint-disable */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, CardContent, Button, } from '@mui/material';
import { gridSpacing } from '../../store/constant';
import { useDispatch } from '../../store';

import MainCard from '../../ui-elements/MainCard';
import { useTheme } from '@mui/material/styles';

import { Icon } from '@iconify/react/dist/iconify.js';
import BreadcrumbsNav from '../../ui-elements/BreadcrumbsTwo';

const QuickStart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const theme = useTheme()
    const mode = theme.palette.mode

    return (
        <MainCard
            content={true}
            title={false}
        >
            <Grid
                container
                className='md:h-full mt-2'
            >
                <Grid
                    item
                    xs={12}
                    lg={12}
                    className='flex flex-col justify-center items-center'
                >
                    <div className='flex flex-col w-full justify-center items-center gap-2 mb-8'>
                        <h1 className='text-2xl font-semibold text-center'>Create New Assistant</h1>
                        <p className='text-center text-tertiary'>Choose how you want to start working with RingUs</p>
                    </div>
                    <Grid
                        className='flex flex-col md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 justify-center items-center'
                    >
                        <CardContent
                            sx={{
                                border: '1px solid',
                                borderColor: theme.palette.border.main,
                                background: mode === 'dark' ? theme.palette.background.gradientDark : theme.palette.background.default,
                                height: '100%'
                            }}
                            className='rounded-xl px-10 min-h-[200px] min-w-[300px] w-[300px] cursor-pointer hover:bg-gray-50'
                            onClick={() => navigate('/dashboard/agents')}
                        >
                            <div className='flex flex-col w-full justify-center items-center'>
                                <div className='flex flex-col justify-center items-center border border-tertiary rounded-full w-[4rem] h-[4rem]'>
                                    <Icon icon="ph:plus" className='w-8 h-8 text-tertiary' />
                                </div>

                                <div className='flex flex-col w-full justify-center items-center gap-2 mt-4'>
                                    <h1 className='text-lg font-semibold'>Start from Scratch</h1>
                                    <h1 className='mb-2 text-center text-tertiary'>
                                        Build your AI Call Agent step by step, tailored to your business needs.
                                    </h1>
                                </div>
                            </div>
                        </CardContent>
                        <CardContent
                            sx={{
                                border: '1px solid',
                                borderColor: theme.palette.border.main,
                                background: mode === 'dark' ? theme.palette.background.gradientDark : theme.palette.background.default,
                                height: '100%'
                            }}
                            className='rounded-xl px-10 min-h-[200px] min-w-[300px] w-[300px] cursor-pointer hover:bg-gray-50'
                            onClick={() => navigate('/dashboard/quick-start/select-template')}
                        >
                            <div className='flex flex-col w-full justify-center items-center'>
                                <div className='flex flex-col justify-center items-center border border-tertiary rounded-full w-[4rem] h-[4rem]'>
                                    <Icon icon="carbon:template" className='w-8 h-8 text-tertiary' />
                                </div>

                                <div className='flex flex-col w-full justify-center items-center gap-2 mt-4'>
                                    <h1 className='text-lg font-semibold'>Use a Template</h1>
                                    <h1 className='mb-2 text-center text-tertiary'>
                                        Select from our library of industry-specific AI agent templates.
                                    </h1>
                                </div>
                            </div>
                        </CardContent>
                        <CardContent
                            sx={{
                                border: '1px solid',
                                borderColor: theme.palette.border.main,
                                background: mode === 'dark' ? theme.palette.background.gradientDark : theme.palette.background.default,
                                height: '100%'
                            }}
                            className='rounded-xl px-10 min-h-[200px] min-w-[300px] w-[300px] cursor-pointer hover:bg-gray-50'
                            onClick={() => navigate('/dashboard/numbers/buy')}
                        >
                            <div className='flex flex-col w-full justify-center items-center'>
                                <div className='flex flex-col justify-center items-center border border-tertiary rounded-full w-[4rem] h-[4rem]'>
                                    <Icon icon="ph:phone-light" className='w-8 h-8 text-tertiary' />
                                </div>

                                <div className='flex flex-col w-full justify-center items-center gap-2 mt-4'>
                                    <h1 className='text-lg font-semibold'>Browse Numbers</h1>
                                    <h1 className='mb-2 text-center text-tertiary'>
                                        Assign a dedicated number to your agent for seamless communication.
                                    </h1>
                                </div>
                            </div>
                        </CardContent>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    )
};

export default QuickStart;
