import { border, borderBottom, borderColor, color, fontSize, height, minHeight, padding, positions, width } from "@mui/system";
import { IconSelector } from "@tabler/icons";
import { StyledIconCalender, StyledIconCalenderTwo, StyledIconSelector } from "../ui-elements/styledComponents/icons";
import { Icon } from "@iconify/react/dist/iconify.js";


export default function componentStyleOverrides(theme, borderRadius, outlinedFilled) {
    const mode = theme.palette.mode;
    const bgColor = mode === 'dark' ? 'black' : '#ffffff';
    const menuSelectedBack = mode === 'dark' ? theme.palette.secondary.main + 15 : theme.palette.secondary?.light;
    const menuSelected = mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary?.tertiary;

    console.log(mode)

    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '30px',
                    padding: '5px 20px',
                    fontSize: '14px',
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    '&.Mui-checked': {
                        color: '#169Cd9',
                        '&:hover': {
                            backgroundColor: 'rgba(22, 156, 217, 0.08)',
                        },
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#169Cd9',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#169Cd9',
                    '&.Mui-checked': {
                        color: '#169Cd9',
                    },
                },
            },
        },

        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '30px',
                    padding: '8px 25px',
                    fontSize: '14px',
                    backgroundColor: mode === 'dark' ? theme.palette.warning.orange : theme.palette.primary.main,
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    '&:hover': {
                        backgroundColor: mode === 'dark' ? theme.palette.warning.orange : theme.palette.primary.main,
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset',
                        transition: 'background-color 1s ease, box-shadow 1s ease'
                    },
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    boxShadow: 'none',
                    '&.MuiPickersPopper-paper': {
                        opacity: 1,
                        border: `1px solid ${theme.palette.border.main}`,
                        borderRadius: '5px',
                        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                    },
                },
                rounded: {
                    borderRadius: '5px',
                },
            },
        },

        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '20px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.tertiary,
                    // paddingTop: '10px',
                    // paddingLeft: '35px !important',
                    borderRadius: '50px !important',
                    '&.Mui-selected': {
                        color: menuSelected,
                        backgroundColor: `white`,
                        '&:hover': {
                            backgroundColor: `white`
                        },
                        '& .MuiListItemIcon-root': {
                            color: menuSelected,
                        }
                    },
                    '&:hover': {
                        backgroundColor: menuSelectedBack,
                        color: menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.palette.text.dark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.palette.text.primary,
                    '&::placeholder': {
                        color: theme.palette.text.primary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: outlinedFilled ? bgColor : 'transparent',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: mode === 'dark' ? theme.palette.text.primary + 28 : theme.palette.grey[400],
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#bdbdbd',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.primary.main
                    },
                    '&:hover .MuiOutlinedInput-root': {
                        borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-root': {
                        borderColor: theme.palette.primary.main,
                    },
                    borderRadius: '10px',
                    height: '50px',
                    '& .Mui-disabled': {
                        background: theme.palette.background.paper
                    },
                },
                input: {
                    fontWeight: 400,
                    background: outlinedFilled ? 'transparent' : 'transparent',
                    padding: '14.5px 14px',
                    borderRadius: '10px',
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4,
                },
                notchedOutline: {
                    borderColor: theme.palette.grey[400], // Static border color
                    borderRadius: '10px',
                },
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: mode === 'dark' ? theme.palette.text.primary + 50 : theme.palette.grey[300]
                    }
                },
                mark: {
                    backgroundColor: theme.palette.background.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        backgroundColor: `${theme.palette.background.default} !important`,
                        borderRadius: 4,
                        color: theme.palette.text.dark,
                        '.MuiChip-deleteIcon': {
                            color: mode === 'dark' ? theme.palette.text.primary + 80 : theme.palette.secondary[200]
                        }
                    }
                },
                popper: {
                    borderRadius: `${borderRadius}px`,
                    boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
                }
            },
            defaultProps: {
                slotProps: {
                    paper: {

                    }
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.palette.divider,
                    opacity: mode === 'dark' ? 0.2 : 1
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: StyledIconSelector, // Replace this with your custom icon
            },
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                },
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    /** checked not prop
                     *"&.Mui-checked": {
                     *    fontSize: "28px"
                     *}
                     */
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.main,
                    background: theme.palette.primary.main
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    },
                    borderRadius: '5px',
                    padding: '5px 5px'
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    fontSize: '16px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: theme.palette.primary.main,
                    '& .MuiTabs-flexContainer': {
                        borderColor: theme.palette.border.main + 20
                    },
                    '& .MuiTab-root': {
                        color: theme.palette.text.primary
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.palette.background.default
                    },
                    '& .Mui-selected': {
                        color: theme.palette.primary.main
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: '1px solid',
                    borderColor: theme.palette.border.main,
                },
                indicator: {
                    backgroundColor: theme.palette.background.main,
                    height: '4px',
                    borderRadius: '10px'
                }
            }
        },
        MuiTabScrollButton: {
            styleOverrides: {
                root: {
                    width: '10px !important',
                    marginBottom: '10px !important'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    maxWidth: '800px !important',
                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px',
                    backgroundColor: mode === 'dark'? theme.palette.background.modalDark : theme.palette.background.default
                },
                backdrop: {
                    backgroundColor: mode === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.3)',
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: mode === 'dark' ? theme.palette.text.primary + 15 : theme.palette.grey[200],
                    '&.MuiTableCell-head': {
                        fontSize: '0.875rem',
                        color: theme.palette.grey[600],
                        fontWeight: 500
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '0px !important'
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.palette.background.paper,
                    background: theme.palette.text.primary,
                    marginTop: '-80px !important',
                    padding: '10px',
                    fontSize: '12px !important',
                },
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem'
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '4px'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: '9px 0px 9px 15px',
                    borderRadius: '3px',
                    marginBottom: '5px',
                    '&.Mui-selected': {
                        backgroundColor: '#D9F5FF',
                        color: '#0C90D0',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#D9F5FF',
                        color: '#0C90D0',
                    },
                    minHeight: 'auto !important',
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    border: `1px solid ${theme.palette.border.main}`
                }
            }
        },
        MuiPopper: {
            styleOverrides: {
                root: {

                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important'
                },
            }
        },
        MuiPickersPopper: {
            styleOverrides: {
                paper: {
                    transition: 'none',
                }
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                IconComponent: StyledIconSelector,
            },
            styleOverrides: {
                paper: {
                    padding: '4px',
                    backgroundColor: `${theme.palette.background.default} !important`,
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important'
                },
            },
            defaultProps: {
                popupIcon: <IconSelector style={{ height: '1.1rem', width: '1.1rem' }} />
            },
        },
        // Mui Table Component style overrides
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    background: theme.palette.background.default,
                    padding: '14px',
                    borderRadius: '8px',
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    background: mode === 'dark' ? theme.palette.background.gradientSecondaryDark : theme.palette.background.paper, // Ensure distinct background color
                    '& .MuiTableRow-root': {
                        background: mode === 'dark' ? theme.palette.background.gradientSecondaryDark : theme.palette.background.paper, // Explicitly set head row color
                        border: `10px solid ${mode === 'dark' ? 'none' : theme.palette.background.paper}`, // Gap effect between rows
                        '& .MuiTableCell-root': {
                            color: mode === 'dark' ? theme.palette.text.light : theme.palette.text.dark,
                        },
                    },
                    '& th:first-child': {
                        borderTopLeftRadius: '8px',
                    },
                    '& th:last-child': {
                        borderTopRightRadius: '8px',
                    },
                },
            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    backgroundColor: mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper, // Rows have a white background
                    border: mode !== 'dark' && '10px solid #F8F9FA', //
                    '& .MuiTableRow-root': {
                        backgroundColor: mode === 'dark' ? theme.palette.background.dark : theme.palette.background.light,
                        border: `5px solid ${mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper}`,
                    },
                },
            },
        },
        // MuiTableRow: {
        //     styleOverrides: {
        //         root: {
        //             borderBottom: '8px solid #F8F9FA', // Gap effect between rows
        //             borderTop: '8px solid #F8F9FA', // Gap effect between rows
        //             backgroundColor: 'white',
        //         },
        //     },
        // },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none !important',
                    fontSize: '0.8rem',  // Default font size
                    '@media (max-width: 600px)': {
                        fontSize: '0.8rem',  // Smaller font size for mobile screens
                    },
                    '@media (max-width: 400px)': {
                        fontSize: '0.8rem',  // Even smaller font size for extra small screens
                    }
                },
                head: {
                    fontWeight: 'semibold',  // Make header text bold
                    '@media (max-width: 600px)': {
                        fontSize: '0.8rem',
                    },
                    '@media (max-width: 400px)': {
                        fontSize: '0.8rem',
                    }
                }
            }
        },
        MuiDatePicker: {
            defaultProps: {
                components: {
                    OpenPickerIcon: StyledIconCalenderTwo, // Override the default calendar icon with custom icon
                },
            },
        },
    };
}
