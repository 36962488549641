// action - state management
import { LOGIN, LOGOUT, SAVE_USER_DATA, SAVE_TWO_FA_DATA, UPDATE_ACTIVE_ORGANIZATION, FETCH_ACCESS_TOKEN, IS_AUTHENTICATING } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

export const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    data: null,
    two_fa_auth_enabled: false,
    active_subscription: false,
    active_org: null,
    member_permission: null,
    auth_type: null, // SOCIAL, EMAIL_PASSWORD
    auth_loading: false,
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action) => {
    switch (action.type) {

        case LOGIN: {
            const { user } = action.payload;
            console.log("LOGIN ACTION CALLED")
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                data: user,
            };
        }
        case LOGOUT: {
            console.log("LOG OUT ACTION CALLED!!!")

            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        case SAVE_USER_DATA: {
            const { user, active_subscription = state.active_subscription, auth_type } = action.payload;
            const newData = { ...state.data, ...user }

            console.log("SAVE_USER_DATA action called", auth_type)

            return {
                ...state,
                data: newData,
                active_subscription: active_subscription,
                auth_type: auth_type
            };
        }
        case SAVE_TWO_FA_DATA: {
            const { two_fa_auth_enabled, user } = action.payload;
            const newData = { ...state.data, ...user }

            return {
                ...state,
                data: newData,
                two_fa_auth_enabled: two_fa_auth_enabled,
            };
        }
        case UPDATE_ACTIVE_ORGANIZATION: {
            const { active_org, member_permission = state.member_permission } = action.payload;
            return {
                ...state,
                active_org: active_org,
                member_permission: member_permission
            };
        }
        case FETCH_ACCESS_TOKEN: {
            const { isInitialized } = action.payload;
            return {
                ...state,
                initialState: isInitialized
            };
        }
        case IS_AUTHENTICATING: {
            const { auth_loading } = action.payload;
            return {
                ...state,
                auth_loading: auth_loading ? auth_loading : initialState.auth_loading
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
