/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    supports: null,
    allReplies: null,
    loading: false,
    replyLoading: false,
    totalCount: 0,
};

const slice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isReplyLoading(state, action) {
            state.replyLoading = action.payload;
        },

        setSupportDataSuccess(state, action) {
            state.supports = action.payload?.data;
            state.totalCount = action.payload?.totalCount;
        },
        setSupportRepliesDataSuccess(state, action) {
            state.allReplies = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getAllUserSupports({ page, limit }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/support/user/all?page=${page}&limit=${limit}`);
            dispatch(slice.actions.setSupportDataSuccess(response.data));

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}


export function createUserSupport(data) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.post(`/support/create`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );

            handleSuccess(response, "Support ticket created successfully", dispatch);

            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function getAllSupportReplies({ ticket_id }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/support/user/all/replies?ticket_id=${ticket_id}`);
            dispatch(slice.actions.setSupportRepliesDataSuccess(response.data?.data));

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            setTimeout(() => {
                dispatch(slice.actions.isLoading(false));
            }, 1000)
        }
    }
}

export function createSupportReply(data, ticket_id) {
    return async () => {
        try {
            dispatch(slice.actions.isReplyLoading(true));

            const response = await axios.post(`/support/send-reply?ticket_id=${ticket_id}`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isReplyLoading(false));
        }
    }
}