/* eslint-disable */
import React, { useEffect } from 'react';
import SignIn from '../../components/auth/Singnin';
import Styles from '../../../styles/landingpage.module.scss';
import Header from '../../../ui-elements/header';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import SignUp from '../../components/auth/SignUp';
import ForgetPassword from '../../components/auth/ForgetPassword';
import { useSelector } from '../../../store';
import TwoFaAuthPage from '../../components/auth/TwoFaAuthPage';
import { StorageManager } from '../../../utils/storageManager';

function index() {
    const { jwtToken } = useAuth();
    const navigate = useNavigate();
    const { isLoggedIn, data: userData, auth_loading } = useSelector((state) => state.account)
    const { menuItems, loadingMenuItems } = useSelector(state => state.dashboard)

    const pathname = window.location.pathname;

    console.log("auth_loading", auth_loading)

    useEffect(() => {
        const token = new StorageManager('ACCESS_TOKEN', 'AUTH', true).getStorage();
        if (isLoggedIn && token) {
            if (userData?.startup_tab_id) {
                //find the link from the menuItems.children where id is userData?.startup_tab_id
                const item = menuItems?.children?.find(item => item.id === userData?.startup_tab_id);
                if (item) {
                    navigate(item?.url);
                    return
                }
            }
            else {
                window.location.href = '/dashboard/quick-start'
            }
        }
    }, [isLoggedIn, jwtToken, userData, menuItems]);


    return (
        <div className={Styles.container}>
            <Header />
            {pathname === '/login' ?
                <SignIn />
                :
                pathname === '/sign-up' ?
                    <SignUp />
                    :
                    pathname === '/forgot-password' ?
                        <ForgetPassword />
                        :
                        pathname === '/auth/two-factor' ?
                            <TwoFaAuthPage />
                            :
                            null
            }
        </div>
    );
}

export default index;
