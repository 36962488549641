import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

// third-party
import { initializeApp } from 'firebase/app';
import {
    getAuth, GoogleAuthProvider, RecaptchaVerifier, signInWithEmailAndPassword, signInWithPopup,
    createUserWithEmailAndPassword, sendPasswordResetEmail, PhoneAuthProvider,
    reauthenticateWithCredential, signInWithRedirect, getRedirectResult
} from 'firebase/auth';
// import 'firebase/compat/auth';
import jwtDecode from 'jwt-decode';

// project imports
import Loader from '../ui-elements/Loader';

import { LOGIN, LOGOUT, SAVE_USER_DATA, SAVE_TWO_FA_DATA, UPDATE_ACTIVE_ORGANIZATION, FETCH_ACCESS_TOKEN, IS_AUTHENTICATING } from '../store/actions';
import accountReducer, { initialState } from '../store/accountReducer';
import axios from '../utils/axios';
import { getUserOrganizations } from '../store/slices/organization';
import { useDispatch, useSelector } from '../store';
import { getAccessToken, getUserData } from '../store/slices/user';
import { StorageManager } from '../utils/storageManager';
import { openConfirmationModal } from '../store/slices/confirmationModal';
import { useNavigate } from 'react-router';
import { getMenuItems } from '../store/slices/dashboard';

initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-'
});

const auth = getAuth();
auth.settings.appVerificationDisabledForTesting = true;

//verify JWT token and its expiration
const isJwtNotExpired = (jwtToken) => {
    try {
        if (!jwtToken) {
            return false;
        }
        const decoded = jwtDecode(jwtToken);
        return decoded?.exp > Date.now() / 1000;
    }
    catch (error) {
        return false;
    }
}

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {

    const [state, dispatch] = useReducer(accountReducer, initialState);
    const apiCallDispatch = useDispatch();
    const navigate = useNavigate()
    const { isInitialized, isLoggedIn, data: userData } = useSelector((state) => state.account)

    const [jwtToken, setJwtToken] = useState(new StorageManager('ACCESS_TOKEN', 'AUTH', true).getStorage() || null)

    const handleLogOut = () => {
        StorageManager.clearNonPersistent()
        // auth.signOut();
        apiCallDispatch({
            type: LOGOUT
        });
    }

    //calling logout api to modify user sessions
    const logout = async () => {
        const sessionId = new StorageManager('SESSION_ID', 'SESSION', true).getStorage()

        if (sessionId) {
            await axios.post('/auth/logout')
                .then((res) => {
                    handleLogOut();
                })
                .catch((error) => {
                    console.log("Error occurred in logout api.", error)
                    handleLogOut();
                })
        }
        else {
            handleLogOut()
        }
    }

    //function to fetch the access by refresh token when access token expired
    const fetchAccessToken = async () => {
        apiCallDispatch({
            type: FETCH_ACCESS_TOKEN,
            payload: {
                isInitialized: false
            }
        });

        try {
            const data = await apiCallDispatch(getAccessToken())
            const jwtToken = data?.accessToken;
            new StorageManager('ACCESS_TOKEN', 'AUTH', true, jwtToken).setStorage()

            apiCallDispatch({
                type: LOGIN,
                payload: {
                    user: data?.data
                }
            });
            setJwtToken(jwtToken)
        }
        catch (error) {
            await logout()
        }
    }


    const fetchMenuItems = async () => {
        try {
            const data = await apiCallDispatch(getMenuItems())

            return data;
        }
        catch (error) {
            console.log("error fetching menu items", error);
        }
    }

    const checkForValidDataInLocalStorage = async () => {

        const accessToken = new StorageManager('ACCESS_TOKEN', 'AUTH', true).getStorage()
        const sessionId = new StorageManager('SESSION_ID', 'SESSION', true).getStorage()
        const refresh_token = new StorageManager('REFRESH_TOKEN', 'SESSION', true).getStorage()
        const deviceId = new StorageManager('DEVICE_ID', 'PERSIST', true).getStorage()

        if (!accessToken || !sessionId || !deviceId || !refresh_token) {
            return false
        }
        else {
            return true
        }
    }

    //use Effect to fetch all organizations of the user and login-------------------
    //This uses Effect logic needs to be optimized-----------------------
    useEffect(() => {
        async function fetch() {

            // if valid data in local storage
            const isValidDataPresent = checkForValidDataInLocalStorage();
            if (!isValidDataPresent) {
                logout()
                return;
            }

            if (jwtToken) {
                //checking expiry of the token.
                const isNotExpired = isJwtNotExpired(jwtToken)

                //if not expired
                if (isNotExpired) {
                    try {
                        const userOrganizations = await apiCallDispatch(getUserOrganizations())
                        const active_org = new StorageManager('ACTIVE_ORG', 'OTHERS').getStorage();

                        //if there is no active_org in local storage, 
                        //set the first organization as active_org and save it in local storage.
                        if (!active_org) {
                            const org_data = {
                                org_id: userOrganizations[0]?.org_id,
                                org_name: userOrganizations[0]?.org_name
                            }
                            const member_permission = userOrganizations[0]?.member_permissions;

                            new StorageManager('ACTIVE_ORG', 'OTHERS', false, org_data).setStorage();

                            //new org data with organization permissions
                            const new_org_data = {
                                ...org_data,
                                org_permissions: userOrganizations[0]?.org_permissions
                            }
                            apiCallDispatch({
                                type: UPDATE_ACTIVE_ORGANIZATION,
                                payload: {
                                    active_org: new_org_data,
                                    member_permission: member_permission
                                }
                            });
                        }
                        else {
                            const org_id = active_org?.org_id;
                            const org_data = userOrganizations.find(org => org.org_id === org_id);

                            //new org data with organization permissions
                            const new_org_data = {
                                org_id: org_data?.org_id,
                                org_name: org_data?.org_name,
                                org_permissions: org_data?.org_permissions
                            }

                            apiCallDispatch({
                                type: UPDATE_ACTIVE_ORGANIZATION,
                                payload: {
                                    active_org: new_org_data,
                                    member_permission: org_data?.member_permissions
                                }
                            });
                        }

                        //getting the user details
                        const user_data = await apiCallDispatch(getUserData());
                        const menu_items = await fetchMenuItems()

                        if (user_data && menu_items) {
                            apiCallDispatch({
                                type: LOGIN,
                                payload: {
                                    user: user_data
                                }
                            });

                            //ending the loading effect after all the required apis have been completed.
                            apiCallDispatch({
                                type: IS_AUTHENTICATING,
                                payload: {
                                    auth_loading: false,
                                }
                            });
                        }
                        else {
                            logout()
                        }
                    }
                    catch (error) {
                        logout()
                    }
                }
                else {
                    await fetchAccessToken();   //fetch access token if it is expired
                }
            }
            else {
                logout()
            }
        }
        fetch()
    }, [apiCallDispatch, jwtToken]);

    //-------------------------------This uses Effect logic needs to be optimized---------------------------------


    useEffect(() => {
        if (isLoggedIn) {
            setTimeout(() => {
                const isTourGuideCompleted = userData?.tour_completed;
                if (!isTourGuideCompleted) {
                    apiCallDispatch(
                        openConfirmationModal({
                            open: true,
                            message: "Welcome to RingUs! Let's get you started with a quick tutorial.",
                            modalType: "ONBOARDING_TOUR_CONF_MODAL",
                            closable: false,
                            handleConfirm: async () => {
                                navigate('/dashboard/numbers')
                            },
                        })
                    );
                }
            }, 1500)
        }
    }, [jwtToken, isLoggedIn, userData])


    //----------------this is very crucial statement-----------
    if (!isInitialized) {
        return <Loader />;
    }
    //----------------------------------------------------------


    const firebaseEmailPasswordSignIn = (email, password) => {
        try {
            return signInWithEmailAndPassword(auth, email, password);
        }
        catch (error) {
            throw error;
        }
    }

    const firebaseGoogleSignIn = () => {
        try {
            const provider = new GoogleAuthProvider();
            return signInWithPopup(auth, provider)
        }
        catch (error) {
            throw error
        }
    };

    // const firebaseGoogleSignIn = () => {
    //     try {
    //         const provider = new GoogleAuthProvider();
    //         return signInWithRedirect(auth, provider)
    //     }
    //     catch (error) {
    //         throw error
    //     }
    // };

    const firebaseRegister = async (email, password) => {
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);
            return res
        }
        catch (error) {
            throw error
        }
    }
    const getAuthRedirectResult = async () => {
        try {
            const data = {
                type: new StorageManager('AUTH_TYPE', 'PERSIST').getStorage(),
                payload: {}
            };

            // Wrap in a Promise to handle the asynchronous behavior of onAuthStateChanged
            data.payload = await new Promise((resolve, reject) => {
                auth.onAuthStateChanged((user) => {
                    if (user) {
                        console.log("Line 309", user); // Check if UserImpl exists
                        resolve(user);
                    }
                    else {
                        reject(new Error("User not authenticated"));
                    }
                });
            });

            console.log("Line 309", data);
            return data;
        }
        catch (error) {
            console.error("Error in getAuthRedirectResult:", error);
            throw error;
        }
    };


    const resetPassword = async (email) => {
        await axios.post('/auth/reset-password', { email: email })
            .then(async (res) => {
                try {
                    await sendPasswordResetEmail(auth, email);
                    return res?.data
                }
                catch (error) {
                    throw error
                }
            })
            .catch((error) => {
                console.log("Error in sending reset link!")
                throw error
            })
    };

    const verifyPhoneNumber = async (phoneNumber) => {
        const appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
            },
            'expired-callback': (error) => {
                console.log('ReCAPTCHA Error', error)
                return error
            }
        });
        try {
            const provider = new PhoneAuthProvider(auth)
            const verificationId = provider.verifyPhoneNumber(phoneNumber, appVerifier)
            return verificationId;
        }
        catch (error) {
            throw error;
        }
    };


    const signInWithOTP = async (verificationId, otp) => {
        try {
            const credential = PhoneAuthProvider.credential(verificationId, otp);
            const result = await reauthenticateWithCredential(auth.currentUser, credential);
            return result;
        }
        catch (error) {
            throw error;
        }
    };

    //saving the user data into the mongoDB
    const saveUserData = async (data) => {

        try {
            const res = await axios.post('/auth/save-user', data)
            //saving the jwt token into local storage
            const accessToken = res?.data?.accessToken;
            const session_id = res?.data?.session?.session_id;
            const device_id = res?.data?.session?.device_id;
            const refresh_token = res?.data?.session?.refresh_token;

            new StorageManager('ACCESS_TOKEN', 'AUTH', true, accessToken).setStorage()
            new StorageManager('SESSION_ID', 'SESSION', true, session_id).setStorage()
            new StorageManager('DEVICE_ID', 'PERSIST', true, device_id).setStorage()
            new StorageManager('REFRESH_TOKEN', 'SESSION', true, refresh_token).setStorage()

            setJwtToken(accessToken)

            return res?.data

        } catch (error) {
            console.log("Error in saving the user data!")
            throw error
        }
    }


    const saveUserDataGoogleLogin = async (data) => {

        try {
            const res = await axios.post('/auth/save-user/google-login', data)
            //saving the jwt token into local storage
            const accessToken = res?.data?.accessToken;
            const session_id = res?.data?.session?.session_id;
            const device_id = res?.data?.session?.device_id;
            const refresh_token = res?.data?.session?.refresh_token;

            new StorageManager('ACCESS_TOKEN', 'AUTH', true, accessToken).setStorage()
            new StorageManager('SESSION_ID', 'SESSION', true, session_id).setStorage()
            new StorageManager('DEVICE_ID', 'PERSIST', true, device_id).setStorage()
            new StorageManager('REFRESH_TOKEN', 'SESSION', true, refresh_token).setStorage()

            setJwtToken(accessToken)
            return res?.data
        }
        catch (error) {
            console.log("Error in saving the user data!")
            throw error
        }
    }

    //creating the user data into the mongoDB
    const createUser = async (data) => {

        try {
            //api call to create the user in mongoDB and firebase 
            const res = await axios.post('/auth/create-user', data)
            //saving the jwt token into local storage
            const accessToken = res?.data?.accessToken;
            const session_id = res?.data?.session?.session_id;
            const device_id = res?.data?.session?.device_id;
            const refresh_token = res?.data?.session?.refresh_token;

            new StorageManager('ACCESS_TOKEN', 'AUTH', true, accessToken).setStorage()
            new StorageManager('SESSION_ID', 'SESSION', true, session_id).setStorage()
            new StorageManager('DEVICE_ID', 'PERSIST', true, device_id).setStorage()
            new StorageManager('REFRESH_TOKEN', 'SESSION', true, refresh_token).setStorage()

            setJwtToken(accessToken)
            return res?.data;
        }
        catch (error) {
            console.log("Error in saving the user data!")
            throw error
        }
    }

    const updateUserDetails = async (uid, data) => {
        try {
            //saving user data into redux store.
            apiCallDispatch({
                type: SAVE_USER_DATA,
                payload: {
                    user: data
                }
            });

            const res = await axios.put(`/auth/update-user/${uid}`, data)
            return res?.data;
        }
        catch (error) {
            console.log(error)
            throw error
        }
    }

    //check for two factor authentication
    const checkTwoFactorAuth = async (uid) => {
        const userInfo = {
            uid: uid,
        }

        try {
            const res = await axios.post('/auth/check-twofa', userInfo)
            //saving the jwt token into local storage
            const data = res.data
            apiCallDispatch({
                type: SAVE_TWO_FA_DATA,
                payload: {
                    user: data?.data,
                    two_fa_auth_enabled: data?.two_fa_auth_enabled,
                }
            });
            return data
        }
        catch (error) {
            console.log("Error in saving the user data!")
            throw error
        }
    }

    //check for two factor authentication
    const getQrCodeForTwoFA = async (user_id) => {
        const userInfo = {
            user_id: user_id,
        }
        try {
            const res = await axios.post('/auth/get-qrcode', userInfo)
            //saving the jwt token into local storage
            const data = res.data?.data
            return data
        }
        catch (error) {
            console.log("Error in generating the qr code.")
            throw error
        }
    }

    //check for two factor authentication
    const verifyTwoFaCode = async (twofa_code, user_id, twofa_enabled = true) => {
        const userInfo = {
            twofa_code: twofa_code,
            user_id: user_id,
            twofa_enabled
        }
        try {
            const res = await axios.post('/auth/verify-twofa', userInfo)
            //saving the jwt token into local storage
            const data = res.data?.data
            return data
        }
        catch (error) {
            console.log("Error in the twofa code.")
            throw error
        }
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => { },
                firebaseGoogleSignIn,
                getAuthRedirectResult,
                logout,
                resetPassword,
                saveUserData,
                createUser,
                updateUserDetails,
                verifyPhoneNumber,
                signInWithOTP,
                jwtToken,
                fetchAccessToken,
                saveUserDataGoogleLogin,
                checkTwoFactorAuth,
                getQrCodeForTwoFA,
                verifyTwoFaCode
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
