
import { Icon } from "@iconify/react/dist/iconify.js"
import { IconButton } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import useConfig from "../../../../hooks/useConfig";

const ModeSwitch = () => {
    const theme = useTheme()
    const mode = theme.palette.mode
    const { onChangeMenuType, config } = useConfig();

    const onChangeMode = (view) => {
        onChangeMenuType(view)
    }

    return (
        <div
            className="flex items-center gap-2 p-1 rounded-md w-fit"
            style={{
                backgroundColor: mode === 'dark' ? theme.palette.background.default : theme.palette.background.gray,
            }}
        >
            <IconButton
                className={`${config.navType === 'light' && 'shadow-card-shadow rounded-sm'}`}
                style={{
                    background: config.navType === 'light' && (mode === "dark" ? theme.palette.secondary.main : theme.palette.background.default),
                    color: theme.palette.text.primary,
                    borderRadius: '6px'
                }}
                onClick={() => onChangeMode('light')}
            >
                <Icon icon="entypo:light-up" style={{ fontSize: '0.8rem', }} />
            </IconButton>
            <IconButton
                className={`${config.navType === 'dark' && 'shadow-card-shadow rounded-sm'}`}
                style={{
                    background: config.navType === 'dark' && (mode === "dark" ? theme.palette.secondary.main : theme.palette.background.default),
                    color: theme.palette.text.primary,
                    borderRadius: '6px'
                }}
                onClick={() => onChangeMode('dark')}
            >
                <Icon icon="material-symbols-light:dark-mode-outline" style={{ fontSize: '0.8rem' }} />
            </IconButton>
        </div>
    )
}

export default ModeSwitch