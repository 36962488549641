import { Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CustomLoadingButton = ({ isLoading, icon = '', text, handleClick, disabled = false, textStyle = '', sx = {} }) => {
    const theme = useTheme();

    return (
        <Button
            type="button"
            variant="outlined"
            className='outlined-button py-4 flex items-center gap-2'
            onClick={handleClick}
            disabled={isLoading || disabled}
            sx={{
                ...sx,
                backgroundColor: theme.palette.background.default
            }}
        >
            {/* <div className='flex '></div> */}
            {isLoading &&
                <CircularProgress size={20} className='mr-2 text-gray-300' />
            }
            {!isLoading && icon}
            <span className={textStyle}>{text}</span>
        </Button>
    );
};

export default CustomLoadingButton;
