import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { MoreHoriz } from '@mui/icons-material';
import {
    Box,
    Typography,
    Link,
    Menu,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const BreadcrumbsNav = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const pathnames = location.pathname.split('/').filter((x) => x);

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNavigate = (path) => {
        switch (path) {
            case '/dashboard':
                navigate('/dashboard/numbers');
                break;
            default:
                navigate(path);
                break;
        }
    }

    const renderBreadcrumbs = () => {
        if (pathnames.length <= 2) {
            // If 2 or fewer routes, show all
            return pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                const isLast = index === pathnames.length - 1;

                return (
                    <React.Fragment key={to}>
                        {index > 0 && <Typography sx={{ color: theme.palette.text.secondary }}>/</Typography>}
                        {isLast ? (
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main
                                }}
                            >
                                {value}
                            </Typography>
                        ) : (
                            <Link
                                component={RouterLink}
                                to={to === '/dashboard' ? '/dashboard/numbers' : to}
                                sx={{
                                    textDecoration: 'none',
                                    '&:hover': { textDecoration: 'underline' },
                                    color: theme.palette.text.secondary
                                }}
                            >
                                {value}{' / '}
                            </Link>
                        )}
                    </React.Fragment>
                );
            });
        }

        // More than 2 routes
        return (
            <>
                {/* Ellipsis */}
                <Box
                    onClick={handleMoreClick}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        color: theme.palette.text.primary
                    }}
                >
                    <MoreHoriz />
                </Box>
                <Typography sx={{ color: theme.palette.text.primary }}>/</Typography>

                {/* Last two routes */}

                {pathnames.slice(-2).map((value, index) => {
                    const to = `/${pathnames.slice(index, pathnames.length - 1).join('/')}`;
                    const isLast = index === 1;

                    return (
                        <React.Fragment key={to}>
                            {isLast ? (
                                <Typography
                                    sx={{ color: theme.palette.primary.main }}
                                >
                                    {value}
                                </Typography>
                            ) : (
                                <>
                                    <Link
                                        component={RouterLink}
                                        to={to === '/dashboard' ? '/dashboard/numbers' : to}
                                        sx={{
                                            textDecoration: 'none',
                                            color: theme.palette.text.primary
                                        }}
                                    >
                                        {value}
                                    </Link>
                                    <Typography sx={{ color: theme.palette.text.primary }}>/</Typography>
                                </>
                            )}
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    return (
        <Box
            sx={{
                position: 'relative',
                zIndex: 10000,
                padding: '20px 18px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}
            >
                {renderBreadcrumbs()}
            </Box>
            <Menu
                id="create-agent"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        width: '130px',
                        marginTop: '30px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                style={{
                    wordWrap: 'break-word'
                }}
            >

                {pathnames.slice(0, pathnames.length - 2).map((value, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    return (
                        <MenuItem
                            key={index}
                            onClick={() => navigate(to === '/dashboard' ? '/dashboard/numbers' : to)}
                            sx={{
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',
                            }}
                        >
                            {value}
                        </MenuItem>
                    );
                })}
            </Menu >
        </Box>
    );
};

export default BreadcrumbsNav;