import { openSnackbar } from '../store/slices/snackbar';
import { openConfirmationModal } from '../store/slices/confirmationModal';
import { setAgentError } from '../store/slices/agent';

export const handleError = async (error, dispatch) => {
    const errorMessage = error?.message || 'Something went wrong';
    const errorCode = error?.errorCode || 'UNKNOWN_EXCEPTION';

    if (errorCode === 'ACCESS_TOKEN_EXPIRED') {
        window.location.reload();
        console.log("Access token expired.")
    }
    else if (errorCode === 'SESSION_EXPIRED') {
        // window.location.reload();
        console.log("Line:15, Session expired.")
    }
    else if (errorCode === 'REFRESH_TOKEN_EXPIRED' || errorCode === 'SESSION_EXPIRED') {
        console.log("came here", "REFRESH_TOKEN_EXPIRED")

    }
    else if (errorCode === 'NULL_SUBSCRIPTION') {
        dispatch(
            openConfirmationModal({
                open: true,
                message: "This action is not reversible.",
                modalType: "NULL_SUBSCRIPTION",
                handleConfirm: async () => {
                    window.location.href = '/dashboard/organization?tab=subscription-details';
                },
            })
        );
    }
    else if (errorCode === 'UPGRADE_SUBSCRIPTION') {
        dispatch(
            openConfirmationModal({
                open: true,
                message: "This action is not reversible.",
                modalType: "UPGRADE_SUBSCRIPTION",
                handleConfirm: async () => {
                    window.location.href = '/dashboard/organization?tab=subscription-details';
                },
            })
        );
    }
    else if (errorCode === 'AUDIT_LOG_DISABLED') {
        return error;
    }
    else if (errorCode === 'INVALID_AGENT_INSTRUCTION') {
        dispatch(setAgentError(
            { name: "instructions", message: errorMessage }
        ));
    }
    else {
        dispatch(openSnackbar({
            open: true,
            message: errorMessage,
            variant: 'alert',
            alert: { color: "error" },
            close: true
        }));
    }
};

export const handleSuccess = (response, message, dispatch) => {

    const successMessage = response?.data?.message || message;

    dispatch(openSnackbar({
        open: true,
        message: successMessage,
        variant: 'alert',
        alert: { color: "success" },
        close: true
    }));
};

