// store/slices/onboardingTourSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    run: false,
    isReady: false,
    stepIndex: 0, // Keep track of the current step index
};

const onboardingTourSlice = createSlice({
    name: 'onboardingTour',
    initialState,
    reducers: {
        startTour: (state) => {
            state.run = true;
            state.isReady = true;
        },
        stopTour: (state) => {
            state.run = false;
            // state.isReady = false;
        },
        setStepIndex: (state, action) => {
            state.stepIndex = action.payload;
        },
        resetTour: (state) => {
            state.run = false;
            state.isReady = false;
            state.stepIndex = 0;
        },
    },
});

export const { startTour, stopTour, setStepIndex, resetTour } = onboardingTourSlice.actions;
export default onboardingTourSlice.reducer;
