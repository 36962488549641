/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, } from '../../utils/apiResponseHandler.js';

const initialState = {
    menuItems: null,
    loadingMenuItems: false
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {

        isMenuItemsLoading(state, action) {
            state.loadingMenuItems = action.payload;
        },

        setMenuItemsDataSuccess(state, action) {
            state.menuItems = action.payload;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMenuItems() {
    return async () => {
        try {
            dispatch(slice.actions.isMenuItemsLoading(true))

            await new Promise((resolve, reject) => setTimeout(resolve, 500))

            const response = await axios.get(`/dashboard/menu`);
            dispatch(slice.actions.setMenuItemsDataSuccess(response.data?.data))

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isMenuItemsLoading(false))
        }
    }
}