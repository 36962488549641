// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const SAVE_TWO_FA_DATA = 'SAVE_TWO_FA_DATA';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
export const SET_PERMISSION = 'SET_PERMISSION';
export const UPDATE_ACTIVE_ORGANIZATION = 'UPDATE_ACTIVE_ORGANIZATION';
export const FETCH_ACCESS_TOKEN = 'FETCH_ACCESS_TOKEN';
export const IS_AUTHENTICATING = 'IS_AUTHENTICATING';
